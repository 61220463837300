<template>
  <div id="app">
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>


export default {
  name: 'App', 
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top:10px;
  background-image: url('https://www.dehouse.cn/static/img/background.png');
  background-size: cover;  
  background-repeat:no-repeat;
  perspective: 1500px;  
  background-attachment: fixed;
}

</style>
