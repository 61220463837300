import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router/index.js';
import store from "./store";
import cookies from 'vue-cookies';

import hljs from 'highlight.js';
import 'github-markdown-css/github-markdown.css';

import 'mavon-editor/dist/css/index.css';

//忽略警告
// Vue.config.silent=true;

// 如果开启了typescript 需要额外安装 npm install @types/highlight.js
// 通过 import * as hljs from 'highlight.js' 引入
Vue.directive('highlight', function (el) {
  const blocks = el.querySelectorAll('pre code')
  blocks.forEach(block => {
    hljs.highlightBlock(block)
  })
})
Vue.prototype.$token = "";
Vue.prototype.$cookies = cookies;
Vue.use(ElementUI);
Vue.use(store);
Vue.config.productionTip = false;


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
